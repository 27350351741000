<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.username"
                outlined
                :label="$t('username')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.email"
                outlined
                :label="$t('email')"
                :rules="[isEmail]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.password"
                outlined
                type="password"
                :label="$t('password')"
                :hint="$t('password should be 8 charcter at least')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="confirmedPassword"
                outlined
                type="password"
                :label="$t('confirm_password')"
                :hint="$t('two password should be same ')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog", "loader"],
  data() {
    return {
      data: {
        name: "",
        password: "",
        username: "",
        email: "",
      },
      confirmedPassword: null,
    };
  },
  methods: {
    submit() {
      this.$emit("createData", this.data);
    },
  },
  computed: {
    validate() {
      return (
        this.data.name == "" ||
        this.data.password == "" ||
        !this.validatePassword ||
        !this.isEmail == true
      );
    },
    isEmail() {
      return this.$isEmail(this.data.email);
    },
    validatePassword() {
      return this.$validatePasswords(
        this.data.password,
        this.confirmedPassword
      );
    },
  },
  watch: {
    dialog(val) {
      this.data = {};
      this.confirmedPassword = null;
    },
  },
};
</script>
