<template>
  <v-row>
    <v-col cols="12" lg="6" md="6">
      <educationalLevel @clicked="$emit('openEducational', $event)" :id="id" />
    </v-col>
    <v-divider vertical />
    <v-col lg="6">
      <terms :id="id" />
    </v-col>
  </v-row>
</template>
<script>
import educationalLevel from "./educationalLevel.vue";
import terms from "./terms.vue";
export default {
  props: ["id", "enableEdit"],
  components: {
    educationalLevel,
    terms,
  },
};
</script>
