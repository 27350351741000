<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="12" sm="6" md="4" lg="6">
              <div v-if="data.image == null" class="text-center">
                <input
                  type="file"
                  @change="handleFileUpload"
                  style="display: none"
                  ref="imageInput"
                />
                <v-icon large @click="$refs.imageInput.click()"
                  >mdi-cloud-upload-outline</v-icon
                >
                <h6>{{ $t("Upload Image") }}</h6>
              </div>

              <div class="subject-image" v-else>
                <v-icon @click="data.image = null" large>mdi-close</v-icon>
                <img :src="src" alt="" />
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { School } from "@/store/common/school";
export default {
  props: ["dialog", "dialogData", "loader"],
  data() {
    return {
      School,
      data: {
        name: "",
        image: null,
      },
    };
  },
  methods: {
    submit() {
      this.$emit("createData", this.data);
    },
    handleFileUpload(event) {
      this.data.image = event.target.files[0];
    },
  },
  computed: {
    validate() {
      return this.data.name == "" || this.data.image == null;
    },
    src() {
      let data = URL.createObjectURL(this.data.image);
      return data;
    },
  },
  watch: {
    dialog(val) {
      this.data.name = "";
    },
  },
};
</script>
<style lang="scss">
.subject-image {
  width: 70%;
  aspect-ratio: 1/1;
  border: 1px solid black;
  border-radius: 100%;
  img {
    width: 100%;
    height: 90%;
    object-fit: cover;
  }
}
</style>
