<template>
  <v-row>
    <v-col lg="9"><students :id="id" :enableEdit="enableEdit" /> </v-col>
    <v-divider vertical></v-divider>
    <v-col lg="3">
      <teachers :id="id" :enableEdit="enableEdit" :type="'eduClass'"></teachers>
    </v-col>
  </v-row>
</template>
<script>
import students from "./students/index.vue";
import teachers from "../teacher&students/teachers/index.vue";

export default {
  props: ["id", "enableEdit"],
  components: {
    students,
    teachers,
  },
};
</script>
