<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add") }}</span>
      </v-card-title>
      <small class="mx-10 "
        ><span class="text-h6">*</span> {{ $t("mean required") }}</small
      >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.first_name"
                outlined
                :label="$t('first_name') + '*'"
                :rules="feildRequired"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.last_name"
                outlined
                :label="$t('last_name') + '*'"
                :rules="feildRequired"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.username"
                outlined
                :label="$t('username') + '*'"
                :rules="feildRequired"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.email"
                outlined
                :label="$t('email') + '*'"
                :rules="[isEmail]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                type="number"
                v-model="data.phone_number"
                outlined
                :label="$t('phone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-autocomplete
                :search-input.sync="searchTerm"
                :items="countryItems"
                v-model="data.country_id"
                item-text="name"
                item-value="id"
                outlined
                :label="$t('country') + '*'"
                :menu-props="{ closeOnClick: true }"
              >
                <template v-if="!stopInfinityScroll" v-slot:append-item>
                  <div
                    v-intersect="onIntersect"
                    class="pa-4 teal--text justify-center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.address"
                outlined
                :label="$t('adress')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.password"
                outlined
                type="password"
                :label="$t('password') + '*'"
                :rules="passwordLength"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="confirmedPassword"
                outlined
                type="password"
                :label="$t('confirm_password')"
                :rules="confirmPassword"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Country } from "@/store/common/country";
export default {
  props: ["dialog", "dialogData", "loader", "countryItems"],
  data() {
    return {
      Country,
      data: {
        first_name: "",
        phone_number: "",
        last_name: "",
        password: "",
        username: "",
        email: "",
        address: "",
        country_id: null,
      },
      confirmedPassword: null,
      searchTerm: "",
      page: 1,
      feildRequired: [
        (value) => {
          if (value) return true;

          return this.$t("this feild is required");
        },
      ],
      passwordLength: [
        (value) => {
          if (value.length >= 8) return true;
          return this.$t("password should be 8 charcter at least");
        },
      ],
      confirmPassword: [
        (value) => {
          if (value == this.data.password) return true;
          return "two password not the same";
        },
      ],
    };
  },
  methods: {
    submit() {
      this.$emit("createData", this.data);
    },
    async onIntersect() {
      this.page += 1;
      await this.$emit("getCountries", {
        page: this.page,
        name: this.searchTerm,
      });
    },
  },
  computed: {
    stopInfinityScroll() {
      return Country.tableData.data.length == 0;
    },
    validate() {
      return (
        this.data.first_name == "" ||
        this.data.last_name == "" ||
        this.data.username == "" ||
        !this.validatePassword ||
        !this.isEmail ||
        this.data.email == ""
      );
    },
    isEmail() {
      return this.$isEmail(this.data.email);
    },
    validatePassword() {
      return this.$validatePasswords(
        this.data.password,
        this.confirmedPassword
      );
    },
  },
  watch: {
    dialog(val) {
      this.data = {};
      this.confirmedPassword = null;
    },
    searchTerm(val) {
      setTimeout(() => {
        this.page = 1;
        this.$emit("getCountries", { page: this.page, name: val });
      }, 500);
    },
  },
};
</script>
