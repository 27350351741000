<template>
  <div v-if="!loader">
    <v-btn
      @click="createDialog = true"
      x-large
      class="ma-6 pa-2 rounded-xl"
      color="primary"
    >
      {{ $t("add new term") }}<v-icon class="ma-2">mdi-plus</v-icon>
    </v-btn>
    <create
      @createData="create"
      @closePopup="createDialog = false"
      :dialog="createDialog"
      :loader="craeteLoader"
    />
    <edit
      @editData="edit"
      @closePopup="editDialog = false"
      :dialog="editDialog"
      :orginalData="editData"
      :loader="editLoader"
    />
    <delete-popup
      @deleteData="deleteItem($event)"
      @closePopup="deleteDialog = false"
      :dialog="deleteDialog"
      :loader="deleteLoader"
      :item="deleteData"
    />

    <v-row>
      <v-col
        lg="6"
        md="6"
        cols="12"
        v-for="(item, index) in items"
        :key="index"
      >
        <card
          :icon="'mdi-book-open'"
          :item="item"
          :editAble="editAble"
          :deleteAble="deleteAble"
        >
          <template #active>
            <v-switch
              @click="active(item)"
              color="green accent-4"
              inset
              v-model="item.isActive"
            >
            </v-switch>
          </template>
        </card>
      </v-col>
    </v-row>
  </div>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>
<script>
import { Terms } from "@/store/common/terms";
import card from "../card.vue";
import create from "@/pages/Dashboard/schools/semesters & managers/semesters/create.vue";
import edit from "@/pages/Dashboard/schools/semesters & managers/semesters/edit.vue";
export default {
  props: ["id", "enableEdit"],
  components: {
    create,
    edit,
    card,
  },
  data() {
    return {
      Terms,
      createDialog: false,
      editDialog: false,
      editData: null,
      deleteDialog: false,
      deleteData: null,
    };
  },
  methods: {
    //create
    async create(data) {
      let payload = {
        name: data.name,
        semester_id: this.id,
      };
      await this.name.post(payload);
      this.createDialog = false;
      this.name.get({ semester_id: this.id });
    },

    //edit
    editAble(data) {
      this.editData = data;
      this.editDialog = true;
    },
    async edit(data) {
      await this.name.edit({ payload: data.data, id: data.id });
      this.editDialog = false;
      this.name.get({ semester_id: this.id });
    },

    //delete
    deleteAble(data) {
      this.deleteData = data;
      this.deleteDialog = true;
    },
    async deleteItem(id) {
      await this.name.deleteItem(id);
      this.deleteDialog = false;
      this.name.get({ semester_id: this.id });
    },

    async active(item) {
      await this.name.edit({
        payload: { isActive: item.isActive },
        id: item.id,

        onError: () => {
          Object.assign(item, { isActive: false });
        },
      });
    },
  },
  computed: {
    name() {
      return Terms;
    },
    items() {
      return this.name.tableData.data;
    },

    //loaders
    loader() {
      return this.name.getState.loading;
    },
    craeteLoader() {
      return this.name.postState.loading;
    },
    editLoader() {
      return this.name.editState.loading;
    },
    deleteLoader() {
      return this.name.deleteState.loading;
    },
  },
  created() {
    this.name.get({ semester_id: this.id });
  },
};
</script>
