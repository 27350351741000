<template>
  <div v-if="!loader">
    <create
      @closePopup="createDialog = false"
      @createData="create($event)"
      :dialog="createDialog"
      :loader="createLoader"
    />
    <edit
      @closePopup="editDialog = false"
      @editData="edit($event)"
      :dialog="editDialog"
      :dialogData="dialogData"
      :loader="editLoader"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteItem($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
      :loader="deleteLoader"
    />

    <v-row class="justify-center">
      <v-col lg="6">
        <v-btn
          @click="createAble"
          x-large
          class=" pa-2 mt-6 rounded-xl"
          color="primary"
        >
          {{ $t("add new manager") }}<v-icon class="ma-2">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(item, i) in Manageres"
        :key="i"
        lg="12"
        md="12"
        sm="12"
        cols="12"
      >
        <card
          :icon="'mdi-account-tie'"
          :item="item"
          :editAble="editAble"
          :deleteAble="deleteAble"
          :type="'manager'"
        />
      </v-col>
    </v-row>
  </div>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>
<script>
import { Users } from "@/store/common/users/manager";
import create from "./create.vue";
import edit from "./edit.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
import card from "../../card.vue";
export default {
  props: ["id", "enableEdit"],
  components: {
    create,
    edit,
    deletePopup,
    card,
  },
  data() {
    return {
      Users,
      createDialog: false,
      editDialog: false,
      dialogData: null,
      deleteDialog: false,
      deleteDialogData: null,
      filterData: {},
    };
  },
  methods: {
    getData(page) {
      Object.assign(this.filterData, { page: page, school_id: this.id });
      Users.get(this.filterData);
    },

    //create
    createAble() {
      this.createDialog = true;
    },
    async create(data) {
      Object.assign(data, { school_id: this.id });
      await Users.post(data);
      this.createDialog = false;
      this.getData(1);
    },

    //edit
    editAble(obj) {
      this.editDialog = true;
      this.dialogData = obj;
    },
    async edit(data) {
      await Users.edit(data.data, data.id);
      this.editDialog = false;
      this.getData(1);
    },

    //delete
    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteItem(id) {
      await Users.deleteItem(id);
      this.deleteDialog = false;
      this.getData(1);
    },
  },
  computed: {
    //data
    Manageres() {
      return Users.tableData.data;
    },
    pagination() {
      return Users.tableData.meta;
    },
    //loaders
    loader() {
      return Users.getState.loading;
    },
    createLoader() {
      return Users.postState.loading;
    },
    editLoader() {
      return Users.editState.loading;
    },
    deleteLoader() {
      return Users.deleteState.loading;
    },
  },
  mounted() {
    this.getData(1);
  },
};
</script>
