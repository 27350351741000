<template>
  <div v-if="!loader" class="pa-4 mt-n10">
    <v-row class="mt-n16 px-6  align-center">
      <v-icon color="primary" size="60">mdi-domain</v-icon>
      <h2 class="primary--text">
        {{ School.tableData.showData.name }}
      </h2>
      <v-icon
        style="z-index: 99;"
        @click="showSchoolInfo = !showSchoolInfo"
        color="primary"
        size="50"
        class="ma-4"
        :class="{ arrowUp: showSchoolInfo }"
      >
        mdi-menu-down</v-icon
      >
    </v-row>
    <v-expand-transition>
      <v-row
        v-if="showSchoolInfo"
        class="ma-auto justify-center"
        style="width: 60%;"
      >
        <v-col class="mt-n2" cols="12" sm="6" md="4" lg="6">
          <div class="d-flex align-center">
            <h3 class="ma-2">{{ $t("name") }} :</h3>
            <h4 class="ma-1">{{ data.name }}</h4>
          </div>
        </v-col>
        <v-col class="mt-n2" cols="12" sm="6" md="4" lg="6">
          <div class="d-flex align-center">
            <h3 class="ma-2">{{ $t("description") }} :</h3>
            <h4 class="ma-1">{{ data.description }}</h4>
          </div>
        </v-col>
        <v-col class="mt-n2" cols="12" sm="6" md="4" lg="6">
          <div class="d-flex align-center">
            <h3 class="ma-2">{{ $t("country") }} :</h3>
            <h4 class="ma-1">{{ data.country.name }}</h4>
          </div>
        </v-col>
        <v-col class="mt-n2" cols="12" sm="6" md="4" lg="6">
          <v-row
            class="mb-n12 align-center"
            v-for="(param, index) in data.params"
            :key="index"
          >
            <v-col class="ma-1" cols="12" sm="6" md="4" lg="4">
              <div class="d-flex align-center">
                <h3 class="mt-2">{{ param.name }} :</h3>
              </div>
            </v-col>
            <v-col class="ma-1" cols="12" sm="6" md="4" lg="7">
              <div class="d-flex align-center">
                <h4>{{ param.value }}</h4>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-n2" cols="12" sm="6" md="4" lg="6">
          <input
            ref="csvInput"
            class="d-none"
            type="file"
            @change="handleFileUpload"
            accept=".csv,.xlsx"
          />
          <v-btn
            @click.stop.prevent="triggerFileInput"
            color="primary"
            class="rounded-lg my-4"
          >
            {{ $t("Add School Sturcture") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-row v-if="enableEdit" class="justify-center">
      <v-icon class="ma-2" @click="addParam" x-large>mdi-plus</v-icon>
      <v-icon class="ma-2" @click="deleteParam" x-large>mdi-minus</v-icon>
    </v-row>
    <v-divider class="mt-4"></v-divider>
    <v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href">
          <span
            @click="move(item)"
            :class="{
              'primary--text':
                item !== breadcrumbsItems[breadcrumbsItems.length - 1],
              disable: item === breadcrumbsItems[breadcrumbsItems.length - 1],
            }"
          >
            {{ $t(item.text) }}</span
          >
        </v-breadcrumbs-item>
      </template>
      <template #divider>
        <v-icon color="primary">mdi-arrow-left</v-icon>
      </template>
    </v-breadcrumbs>
    <component
      @openSemester="nextStep($event, 'educationalLevel')"
      @openEducational="nextStep($event, 'grade')"
      @openGrade="nextStep($event, 'eduClassSubject')"
      @openEduClass="nextStep($event, 'teachersStudents')"
      @openSubject="nextStep($event, 'stations')"
      @openStation="nextStep($event, 'leasons')"
      @openLeason="nextStep($event, 'exercises')"
      :enableEdit="enableEdit"
      :id="componentType == 'semesters' ? id : levelId"
      :is="componentType"
    ></component>
  </div>
  <pulse-loader
    v-else
    :loading="true"
    size="20px"
    :color="$vuetify.theme.themes.light.primary"
  />
</template>
<script>
//data
import { School } from "@/store/common/school";
import { Country } from "@/store/common/country";

//components
import semesters from "./semesters & managers/index.vue";
import educationalLevel from "./educational_level&terms/index.vue";
import grade from "./grades/index.vue";
import eduClassSubject from "./eduClass&subject/index.vue";
import teachersStudents from "./teacher&students/index.vue";
import stations from "./stations/index.vue";
import leasons from "./leasons/index.vue";
import exercises from "./execises/index.vue";
export default {
  components: {
    semesters,
    educationalLevel,
    grade,
    eduClassSubject,
    teachersStudents,
    stations,
    leasons,
    exercises,
  },
  data() {
    return {
      School,
      Country,
      editData: {},
      levelId: null,
      componentType: "semesters",
      searchTerm: "",
      showSchoolInfo: false,
      breadcrumbsItems: [
        {
          text: "semesters",
          id: null,
          type: "semesters",
        },
      ],
      countryItems: [],
      page: 0,
    };
  },
  methods: {
    handleFileUpload(e) {
      let payload = {
        studentsCsv: e.target.files[0],
        school_id: this.id,
      };
      School.importFromCsv(payload);
    },
    triggerFileInput() {
      this.$refs.csvInput.click();
    },
    //edit mehods

    //move deep in tree
    nextStep(data, to) {
      this.componentType = to;
      this.levelId = data.id;
      this.breadcrumbsItems.push({ type: to, text: data.name, id: data.id });
    },

    //move by breadcrumbs
    move(data) {
      if (this.componentType == data.type) return;
      let index = this.breadcrumbsItems.indexOf(data);
      if (index == 0) {
        this.componentType = "semesters";
        this.breadcrumbsItems.splice(1);
        return;
      }
      this.breadcrumbsItems.splice(index + 1);
      this.componentType = data.type;
      this.levelId = data.id;
    },
  },
  computed: {
    enableEdit() {
      return this.$route.params.type == "edit";
    },

    //params
    id() {
      return this.$route.params.id;
    },

    //data
    data() {
      return { ...School.tableData.showData };
    },
    country() {
      return Country.tableData.data;
    },

    //loaders
    loader() {
      return School.showState.loading;
    },

    //infinity load
    stopInfinityScroll() {
      return Country.tableData.data.length == 0;
    },
  },

  async created() {
    await School.show(this.id);
    this.countryItems.push(this.data.country);
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 900;
}
h3 {
  font-weight: 900;
  color: gray;
}
h4 {
  font-weight: 800;
}
.v-breadcrumbs .primary--text {
  color: red;
  text-decoration: none;
  font-weight: 800;
  cursor: pointer;
}
.disable {
  color: gray;
  text-decoration: none;
  cursor: default;
}
.arrowUp {
  transform: rotate(180deg);
}
</style>
