<template>
  <v-hover>
    <template v-slot="{ hover }">
      <v-card
        @click="$emit('clicked', item)"
        class="mx-auto rounded-xl"
        max-width="344"
        outlined
        :class="{ 'my-card-hover': hover }"
        color="blue darken-1"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title
              v-if="type == 'manager'"
              class="text-p mb-1 white--text"
            >
              <span class="mx-1"> {{ item.first_name }}</span>
              <span class="mx-1">{{ item.last_name }}</span>
            </v-list-item-title>
            <v-list-item-title v-else class="text-p mb-1 white--text">
              <span dir="ltr"> {{ item.name }}</span>
              <span v-if="item.type"> ( {{ $t(item.type) }} ) </span>
            </v-list-item-title>
          </v-list-item-content>
          <v-fab-transition v-if="hover" class="my-card-edit-delete">
            <div class="d-flex align-center">
              <v-icon
                v-if="editAble"
                @click.stop="edit"
                class="ma-1"
                color="white"
                >mdi-pencil</v-icon
              >
              <v-icon
                v-if="deleteAble"
                @click.stop="deleteItem"
                class="ma-1"
                color="deep-orange darken-4"
                >mdi-delete</v-icon
              >
              <slot name="active"></slot>
            </div>
          </v-fab-transition>
          <v-fab-transition v-else>
            <v-icon color="white" x-large>{{ icon }}</v-icon>
          </v-fab-transition>
        </v-list-item>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
    editAble: {
      type: Function,
    },
    deleteAble: {
      type: Function,
    },

    icon: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  methods: {
    changeActiveState() {
      this.activeAble(this.item);
    },
    edit(obj) {
      this.editAble(this.item);
    },
    deleteItem(obj) {
      this.deleteAble(this.item);
    },
  },
};
</script>
