<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("edit") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="justify-center align-center">
            <v-col cols="12" sm="6" md="4" lg="6">
              <div v-if="data.image == null" class="text-center">
                <input
                  type="file"
                  @change="handleFileUpload"
                  style="display: none"
                  ref="imageInput"
                />
                <v-icon large @click="$refs.imageInput.click()"
                  >mdi-cloud-upload-outline</v-icon
                >
                <h6>{{ $t("Upload Image") }}</h6>
              </div>

              <div v-else>
                <v-icon @click="data.image = null" large>mdi-close</v-icon>
                <img :src="src" alt="" />
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog", "orginalData", "loader"],
  data() {
    return {
      data: {},
      imgSrc: null,
    };
  },
  methods: {
    submit() {
      for (let key in this.data) {
        if (this.data[key] === this.orginalData[key]) {
          delete this.data[key];
        }
      }
      this.$emit("editData", { data: this.data, id: this.orginalData.id });
    },
    handleFileUpload(event) {
      this.imgSrc = event.target.files[0];
      this.data.image = this.imgSrc;
    },
  },
  computed: {
    validate() {
      return this.data.name == "";
    },
    src() {
      if (this.imgSrc == null) {
        let data = "https://back.samainnovation.com/" + this.data.image;
        return data.replace("public", "storage");
      } else {
        let data = URL.createObjectURL(this.imgSrc);
        return data;
      }
    },
  },

  watch: {
    orginalData(val) {
      this.data = { ...val };
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 70%;
  height: 90%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
</style>
