import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";

export default class csv {
  getState = new State();

  async get(endPoint) {
    let request = new Request({
      method: "get",
      endpoint: `csv_file/${endPoint}`,
      responseType: "arraybuffer",
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          const blob = new Blob([value], {});
          const csvUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = csvUrl;
          link.download = "students.csv";
          link.click();
        },
        onError: (error) => {
          toast(i18n.t(error.type), { timeout: 4000, rtl: true }, "error");
        },
      }
    );
  }
}

export const Csv = new csv();
