<template>
  <v-row class="justify-start">
    <v-col lg="3">
      <managers :id="id" />
    </v-col>
    <v-divider vertical></v-divider>
    <v-col lg="6">
      <semesters @openSemester="$emit('openSemester', $event)" :id="id" />
    </v-col>
    <v-divider vertical></v-divider>
    <v-col lg="3">
      <teachers :id="id" :enableEdit="enableEdit" :type="'school'"></teachers>
    </v-col>
  </v-row>
</template>
<script>
import semesters from "./semesters/index.vue";
import managers from "./manegaers/index.vue";
import teachers from "../teacher&students/teachers/index.vue";

export default {
  mixins: [semesters],
  props: ["id", "enableEdit"],
  components: {
    semesters,
    managers,
    teachers,
  },
};
</script>
