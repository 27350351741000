<template>
  <div v-if="!loader">
    <v-row>
      <v-col lg="6">
        <v-btn
          @click="createDialog = true"
          x-large
          class="ma-6 pa-2 rounded-xl"
          color="primary"
        >
          {{ $t("add new subject") }}<v-icon class="ma-2">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <create
      @createData="create"
      @closePopup="createDialog = false"
      :dialog="createDialog"
      :loader="craeteLoader"
    />
    <edit
      @editData="edit"
      @closePopup="editDialog = false"
      :dialog="editDialog"
      :orginalData="editData"
      :loader="editLoader"
    />
    <delete-popup
      @deleteData="deleteItem($event)"
      @closePopup="deleteDialog = false"
      :dialog="deleteDialog"
      :loader="deleteLoader"
      :item="deleteData"
    />
    <v-row>
      <v-col
        v-for="(item, i) in subjects"
        :key="i"
        lg="6"
        md="6"
        sm="12"
        cols="12"
      >
        <card
          @clicked="$emit('openSubject', $event)"
          :icon="'mdi-notebook-edit'"
          :item="item"
          :editAble="editAble"
          :deleteAble="deleteAble"
        />
      </v-col>
    </v-row>
  </div>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>
<script>
import { Subject } from "@/store/common/subject";
import card from "../../card.vue";
import create from "./create.vue";
import edit from "./edit.vue";
export default {
  props: ["id", "enableEdit"],
  components: {
    create,
    edit,
    card,
  },
  data() {
    return {
      Subject,
      params_id: "grade_id",
      createDialog: false,
      editDialog: false,
      editData: null,
      deleteDialog: false,
      deleteData: null,
    };
  },
  methods: {
    getData() {
      let params = {
        grade_id: this.id,
      };
      this.name.get(params);
    },
    //create
    async create(data) {
      let payload = {
        name: data.name,
        image: data.image,
        grade_id: this.id,
      };
      await this.name.post(payload);
      this.createDialog = false;
      this.getData();
    },

    //edit
    editAble(data) {
      this.editData = data;
      this.editDialog = true;
    },
    async edit(data) {
      await this.name.edit(data.data, data.id);
      this.editDialog = false;
      this.getData();
    },

    //delete
    deleteAble(data) {
      this.deleteData = data;
      this.deleteDialog = true;
    },
    async deleteItem(id) {
      await this.name.deleteItem(id);
      this.deleteDialog = false;
      this.getData();
    },
  },
  computed: {
    name() {
      return Subject;
    },
    subjects() {
      return this.name.tableData.data;
    },

    //loaders
    loader() {
      return this.name.getState.loading;
    },
    craeteLoader() {
      return this.name.postState.loading;
    },
    editLoader() {
      return this.name.editState.loading;
    },
    deleteLoader() {
      return this.name.deleteState.loading;
    },
  },
  created() {
    this.getData();
  },
};
</script>
