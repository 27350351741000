<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add") }}</span>
      </v-card-title>
      <v-card-text>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="6" md="4" lg="6">
            <v-text-field
              v-model="data.name"
              outlined
              :label="$t('name')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="6">
            <v-select
              v-model="data.type"
              :items="items"
              outlined
              item-text="name"
              item-value="value"
              :label="$t('type')"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="6">
            <v-autocomplete
              v-model="data.term"
              :items="terms"
              outlined
              item-text="name"
              item-value="id"
              :label="$t('term')"
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>
        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Terms } from "../../../../store/common/terms";
export default {
  props: ["dialog", "dialogData", "loader"],
  data() {
    return {
      Terms,
      data: {
        name: "",
        type: "",
        term: "",
      },
    };
  },
  methods: {
    submit() {
      this.$emit("createData", this.data);
    },
  },
  computed: {
    validate() {
      return (
        this.data.name == "" || this.data.type == "" || this.data.term == ""
      );
    },
    items() {
      return [
        {
          name: this.$t("makka"),
          value: "makka",
        },
        {
          name: this.$t("mountain"),
          value: "mountain",
        },
        {
          name: this.$t("rivers"),
          value: "rivers",
        },
        {
          name: this.$t("desert"),
          value: "desert",
        },
        {
          name: this.$t("city"),
          value: "city",
        },
        {
          name: this.$t("madina"),
          value: "madina",
        },
        {
          name: this.$t("islamic"),
          value: "islamic",
        },

        {
          name: this.$t("space"),
          value: "space",
        },
        {
          name: this.$t("sea"),
          value: "sea",
        },
        {
          name: this.$t("library"),
          value: "library",
        },
        {
          name: this.$t("plains"),
          value: "plains",
        },
        {
          name: this.$t("insidecity"),
          value: "insidecity",
        },
      ];
    },
    terms() {
      return Terms.tableData.data;
    },
  },
  watch: {
    dialog(val) {
      this.data.name = "";
      this.data.type = "";
      this.data.term = "";
    },
  },
};
</script>
