<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("edit") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog", "orginalData", "loader"],
  data() {
    return {
      data: {},
    };
  },
  methods: {
    submit() {
      for (let key in this.data) {
        if (this.data[key] === this.orginalData[key]) {
          delete this.data[key];
        }
      }
      this.$emit("editData", { data: this.data, id: this.orginalData.id });
    },
  },
  computed: {
    validate() {
      return this.data.name == "";
    },
  },

  watch: {
    orginalData(val) {
      this.data = { ...val };
    },
  },
};
</script>
