<template>
  <div v-if="!loader">
    <create
      @closePopup="createDialog = false"
      @createData="create($event)"
      :dialog="createDialog"
      :loader="createLoader"
    />
    <edit
      @closePopup="editDialog = false"
      @editData="edit($event)"
      :dialog="editDialog"
      :dialogData="dialogData"
      :loader="editLoader"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteItem($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
      :loader="deleteLoader"
    />

    <v-row class="justify-space-around align-center">
      <v-col v-if="type == 'school'" class="d-flex justify-center" lg="6">
        <v-btn
          @click="createAble"
          x-large
          class=" pa-2 rounded-xl"
          color="primary"
        >
          {{ $t("add new teacher") }}<v-icon class="ma-2">mdi-plus</v-icon>
        </v-btn>
      </v-col>

      <v-col
        v-if="type == 'eduClass'"
        class="d-flex justify-space-around "
        lg="9"
      >
        <v-autocomplete
          class="d-flex justify-center"
          deletable-chips
          item-text="name"
          item-value="id"
          :items="teacherList"
          v-model="selectedTeachers"
          chips
          :label="$t('attach new teacher to this edu class')"
          outlined
          multiple
          dense
          rounded
          ref="autocomplete"
          :menu-props="{ closeOnClick: true }"
        >
          <template v-if="!stopIntersect" v-slot:append-item>
            <div
              v-intersect="onIntersectTeacher"
              class="pa-4 teal--text justify-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div> </template
        ></v-autocomplete>
      </v-col>
      <v-col v-if="type == 'eduClass'" lg="2"
        ><v-btn
          @click="attachTeacher"
          rounded
          color="primary"
          :loading="attachLoading"
          class="mt-n8"
          >{{ $t("add") }}</v-btn
        ></v-col
      >
    </v-row>
    <v-row class="justify-center">
      <v-col
        v-for="(item, i) in Teachers"
        :key="i"
        lg="12"
        md="12"
        sm="12"
        cols="12"
      >
        <card
          :icon="'mdi-account-tie-voice'"
          @clicked="$emit('', $event)"
          :item="item"
          :editAble="type == 'school' ? editAble : null"
          :deleteAble="deleteAble"
        />
      </v-col>
    </v-row>
  </div>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>
<script>
import { Users } from "@/store/common/users/teacher";
import create from "./create.vue";
import edit from "./edit.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
import card from "../../card.vue";
export default {
  props: ["id", "enableEdit", "type"],
  components: {
    create,
    edit,
    deletePopup,
    card,
  },
  data() {
    return {
      Users,
      createDialog: false,
      editDialog: false,
      dialogData: null,
      deleteDialog: false,
      deleteDialogData: null,
      page: 0,
      teacherList: [],
      selectedTeachers: [],
      filterData: {},
    };
  },
  methods: {
    //get data teachers for all school
    getData(page) {
      Object.assign(this.filterData, { page: page, school_id: this.schoolId });
      Users.get(this.filterData);
      this.filterData = {};
    },
    //get data teachers for edu class
    getDataEduClass(page) {
      Object.assign(this.filterData, { page: page, edu_class_id: this.id });
      Users.get(this.filterData);
      this.filterData = {};
    },
    async onIntersectTeacher() {
      this.page += 1;
      await Users.getAutoCompleteData({
        page: this.page,
        school_id: this.schoolId,
      });
      this.teacherList = this.teacherList.concat(this.getAutoCompleteTeachers);
    },
    //filter
    setFilterData(data) {
      this.filterData = data;
      this.getData(1);
    },
    clearFilterData() {
      this.filterData = {};
      this.getData(1);
    },

    //create
    createAble() {
      this.createDialog = true;
    },
    async create(data) {
      Object.assign(data, { school_id: this.schoolId });
      await Users.post(data);
      this.createDialog = false;
    },

    async attachTeacher() {
      await Users.attachTeacherToEduClass(this.id, {
        teacher_ids: this.selectedTeachers,
      });
      await this.getDataEduClass(1);
      this.selectedTeachers = [];
    },

    //edit
    editAble(obj) {
      this.editDialog = true;
      let item = {
        id: obj.id,
        name: obj.name,
        password: obj.password,
        username: obj.user.username,
        email: obj.user.email,
      };
      this.dialogData = item;
    },
    async edit(data) {
      await Users.edit(data.data, data.id);
      this.editDialog = false;
    },

    //delete
    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteItem(id) {
      if (this.type == "school") {
        await Users.deleteItem(id);
      } else if (this.type == "eduClass") {
        await Users.dettachTeacherFromEduClass(this.id, {
          teacher_id: id,
        });
        await this.getDataEduClass(1);
      }
      this.deleteDialog = false;
    },
  },
  computed: {
    //data

    Teachers() {
      return Users.tableData.data;
    },
    pagination() {
      return Users.tableData.meta;
    },
    schoolId() {
      return this.$route.params.id;
    },
    //loaders
    loader() {
      return Users.getState.loading;
    },
    createLoader() {
      return Users.postState.loading;
    },
    editLoader() {
      return Users.editState.loading;
    },
    deleteLoader() {
      return Users.deleteState.loading;
    },

    getAutoCompleteTeachers() {
      return Users.tableData.autoCompleteData;
    },
    stopIntersect() {
      return Users.tableData.autoCompleteData.length == 0;
    },
    attachLoading() {
      return Users.postState.loading;
    },
  },
  async mounted() {
    this.onIntersectTeacher();
    if (this.type == "school") {
      await Users.get({ page: 1, school_id: this.schoolId });
    } else {
      await Users.get({ page: 1, edu_class_id: this.id });
    }
  },
};
</script>
