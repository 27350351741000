<template>
  <v-row>
    <v-col lg="6">
      <v-row class="px-4 justify-center"
        ><table-filter
          @getData="getData($event)"
          @clearData="clearFilter"
          :types="types"
          :date="true"
        />
      </v-row>
      <selected
        @submit="attachItems($event)"
        @updatePage="getData({ page: $event })"
        :items="notSelectedItems"
        :headers="headers"
        :meta="notSelectedMeta"
        :loader="notSelectedLoader"
        :state="notSelectedState"
      />
    </v-col>

    <v-divider vertical></v-divider>

    <v-col lg="6">
      <selected
        @submit="deAttachItems($event)"
        @updatePage="getSelectedData({ page: $event })"
        :items="selectedItems"
        :headers="headersLeason"
        :meta="selectedMeta"
        :loader="selectedLoader"
        :state="selectedState"
        :label="'exercise in this leason'"
        :id="id"
        :excludeId="id"
        :expandable="true"
        :moveable="true"
      />
    </v-col>
    <v-dialog
      v-if="Exams.postState.loading"
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
      style="z-index: 99;"
    >
      <v-card color="primary" dark>
        <span class="ma-2"> {{ $t("adding") }}</span>
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { Exercise } from "@/store/exercise/add";
import { Lesson as Exams } from "@/store/common/lesson";
import selected from "../../../teacher/exams/selected.vue";
import tableFilter from "../../../Dashboard/Exercise/exerciseFilter.vue";

export default {
  props: ["id"],
  components: {
    selected,
    tableFilter,
  },
  data() {
    return {
      Exercise,
      Exams,
      dialog: true,
      filterData: {},
      notSelectedState: false,
      selectedState: false,
      headers: {
        id: {
          slot: "id",
        },

        name: {
          name: "name",
          keys: ["name"],
        },

        type: {
          name: "type",
          keys: ["type"],
        },
        type: {
          name: "type",
          keys: ["type"],
        },
        select: {
          slot: "select",
        },
      },
      headersLeason: {
        id: {
          name: "id",
          keys: ["serial"],
        },

        name: {
          name: "name",
          keys: ["name"],
        },

        type: {
          name: "type",
          keys: ["type"],
        },
        type: {
          name: "type",
          keys: ["type"],
        },
        select: {
          slot: "select",
        },
        show: {
          slot: "show",
        },
      },
    };
  },
  methods: {
    getData(data) {
      Object.assign(data, { exclude_lesson_id: this.id });
      Object.assign(this.filterData, data);
      Exercise.get(this.filterData);
    },
    getSelectedData(data) {
      Object.assign(data, { nullableParent_with_lesson_id: this.id });
      Object.assign(data, { paginated: 0 });
      Exercise.getByLessonId(data);
    },
    async attachItems(data) {
      if (Exams.postState.loading) return;
      await Exams.addExercise(
        this.id,
        { exercises_ids: data },
        {
          onSuccess: () => {
            this.notSelectedState = true;
            this.getData({ page: 1 });
            this.getSelectedData({ page: 1 });
          },
        }
      );
      this.notSelectedState = false;
    },
    async deAttachItems(data) {
      if (Exams.postState.loading) return;
      await Exams.deleteExercises(
        this.id,
        { exercises_ids: data },
        {
          onSuccess: () => {
            this.selectedState = true;
            this.getData({ page: 1 });
            this.getSelectedData({ page: 1 });
          },
        }
      );
      this.selectedState = false;
    },
    clearFilter() {
      this.filterData = {};
      this.getData({ page: 1 });
    },
  },
  computed: {
    data() {
      return Exams.tableData.showData;
    },
    selectedItems() {
      return Exercise.tableData.lessonData;
    },
    selectedMeta() {
      return Exercise.tableData.lessonDataMeta;
    },
    selectedLoader() {
      return Exercise.getByLessonIdState.loading;
    },

    notSelectedItems() {
      return Exercise.tableData.data;
    },
    notSelectedMeta() {
      return Exercise.tableData.meta;
    },
    notSelectedLoader() {
      return Exercise.getState.loading;
    },
    types() {
      return Exercise.types;
    },
  },
  created() {
    Exams.show(this.id);
    this.getData({ page: 1 });
    this.getSelectedData({ page: 1 });
  },
};
</script>
<style lang="scss" scoped>
h1,
h2 {
  color: #2196f3;
  font-weight: 900;
}

p {
  font-weight: 600;
}
</style>
