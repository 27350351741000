<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("edit") }}</span>
      </v-card-title>
      <v-card-text>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="data.name"
              outlined
              :label="$t('name')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-select
              v-model="data.type"
              :items="items"
              outlined
              item-text="name"
              item-value="value"
              :label="$t('type')"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-autocomplete
              v-model="data.term"
              :items="terms"
              outlined
              item-text="name"
              item-value="id"
              :label="$t('term')"
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import create from "./create.vue";
import { Terms } from "../../../../store/common/terms";

export default {
  mixins: [create],
  props: ["dialog", "orginalData", "loader"],
  data() {
    return {
      Terms,
      data: {},
    };
  },
  methods: {
    submit() {
      for (let key in this.data) {
        if (this.data[key] === this.orginalData[key]) {
          delete this.data[key];
        }
      }
      this.$emit("editData", { data: this.data, id: this.orginalData.id });
    },
  },
  computed: {
    terms() {
      return Terms.tableData.data;
    },
    validate() {
      return (
        this.data.name == "" || this.data.type == "" || this.data.term == ""
      );
    },
  },
  watch: {
    orginalData(val) {
      this.data = { ...val };
      this.data.term = this.data.term_id;
    },
  },
};
</script>
