<template>
  <div>
    <v-row class="justify-end mb-4 mt-n12">
      <v-btn
        :loading="Grade.getState.loading"
        @click="publishFiles"
        rounded
        class="white--text primary"
        >{{ $t("Publish Files") }}</v-btn
      >
    </v-row>
    <v-row class="justify-space-between">
      <v-col lg="6">
        <subjects
          @openSubject="$emit('openSubject', $event)"
          :id="id"
          :enableEdit="enableEdit"
        ></subjects>
      </v-col>

      <v-divider vertical></v-divider>
      <v-col lg="6">
        <edu-class
          @openEduClass="$emit('openEduClass', $event)"
          :id="id"
          :enableEdit="enableEdit"
        ></edu-class>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { Grade } from "@/store/common/grade";
import subjects from "./subject/index.vue";
import eduClass from "./eduClass.vue";
export default {
  props: ["id", "enableEdit"],
  components: {
    subjects,
    eduClass,
  },
  data: () => ({
    Grade,
  }),
  methods: {
    publishFiles() {
      Grade.publishFiles(this.id);
    },
  },
};
</script>
